import request from "../customUtil/request"

export async function GetListTaxFee() {
  return request("/admin/super/fee/default", {
    method: "GET",
  })
}

export async function updateTaxFee(data) {
  return request("/admin/super/fee/default", {
    method: "PUT",
    data: data,
  })
}

export async function deleteTaxFee(data) {
  return request("/admin/super/fee/default", {
    method: "DELETE",
    data: data,
  })
}

export async function getApplicationfee(data) {
  return request("/admin/applicationfee", {
    method: "POST",
    data,
  })
}
