import { Tabs } from "antd"
import React, { useContext } from "react"

import { ListProductsContext } from "."

const { TabPane } = Tabs

const TabActivePage = () => {
  const { statusCount, pageActive, setPageActive } = useContext(
    ListProductsContext
  )
  const onChange = (key) => {
    setPageActive(key)
  }

  return (
    <Tabs
      defaultValue={pageActive}
      onChange={onChange}
      size="large"
      className="border border-[#e5e7eb] !mt-5"
      tabBarStyle={{
        paddingLeft: 20,
      }}
    >
      <TabPane tab="すべて" key="1"></TabPane>
      <TabPane
        tab={
          <span>
            出品中
            <span className="count_border">{statusCount?.countNotDraft}</span>
          </span>
        }
        key="2"
      ></TabPane>
      {/* <TabPane
          tab={
            <span>
              申請中
              <span className="count_border">{statusCount?.countPROPOSED}</span>
            </span>
          }
          key="3"
        >
          <Proposed />
        </TabPane> */}
      <TabPane
        tab={
          <span>
            下書き
            <span className="count_border">{statusCount?.countDraft}</span>
          </span>
        }
        key="4"
      ></TabPane>

      {/* <TabPane tab="出品終了" key="5">
          <Proposed />
        </TabPane> */}
    </Tabs>
  )
}

export default TabActivePage
