import React, { useContext, useEffect, useState } from "react"
import { Button, Input, Space, Popconfirm, Spin } from "antd"
import {
  openNotificationChangeSuccess,
  openNotificationFail,
} from "../../../components/notification"
import { onKeyPressNumber } from "../../../customUtil/global"
import { deleteTaxFee, updateTaxFee } from "../../../services/taxFee"

import { ListProductsContext } from "."
import textAndRules from "../../../utils/textAndRules"
const UpdateFee = ({ record, type, storeId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [numberBefore, setNumberBefore] = useState(null)
  const [numberAfter, setNumberAfter] = useState(null)
  const [disable, setDisable] = useState(true)
  const { handlegetListProduct } = useContext(ListProductsContext)
  const [applicationId, setApplicationId] = useState(
    record.applicationFeeArr?.find((ele) => ele.type == type)?.id
  )

  const handleUpdateTaxFee = async () => {
    setIsLoading(true)
    const { response } = await updateTaxFee({
      storeId: storeId,
      fee: Number(numberAfter),
      type: "applicationFee",
      applicationId: applicationId,
      applicationType: type,
      productId: record.id,
    })
    if (response.status == 200) {
      setIsLoading(false)
      setNumberBefore(numberAfter)
      setDisable(false)
      setApplicationId(response.data?.applicationId)
      openNotificationChangeSuccess(textAndRules.updateSuccess, "", "#389e0d")
    } else {
      openNotificationFail(
        "ただいま、サーバが混み合っています。",
        "しばらくしてから再度操作してください。",
        "#f81d22"
      )
    }
  }
  useEffect(() => {
    const dataDefault = record.applicationFeeArr.find((ele) => ele.type == type)
    if (dataDefault) {
      setDisable(false)
    }
    setNumberBefore(dataDefault?.fee)
    setNumberAfter(dataDefault?.fee)
  }, [record])
  return (
    <Spin spinning={isLoading}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Space size={5} direction="horizontal">
          <Input
            value={numberAfter}
            type="number"
            disabled={isLoading}
            style={{ width: 100, height: 25 }}
            onKeyPress={(e) => {
              if (e.key === "Enter" && numberAfter !== numberBefore) {
                handleUpdateTaxFee()
              }
              onKeyPressNumber(e)
            }}
            onChange={(e) => {
              if (e.target.value) {
                setNumberAfter(Number(e.target.value))
                return
              }
              setNumberAfter(0)
            }}
            onBlur={(e) => {
              if (numberAfter == numberBefore) {
                return
              }
              handleUpdateTaxFee()
            }}
          />
          <div>{"%"}</div>
        </Space>
        <Space
          size={5}
          style={{
            padding: "0px 0px 0px 1rem",
            borderLeft: "1px solid #CCCCCC",
          }}
        >
          <Popconfirm
            placement="bottomLeft"
            title={
              <div style={{ fontSize: 14, fontWeight: 600 }}>
                {`本当に実行しますか？ `}
              </div>
            }
            onConfirm={async () => {
              setIsLoadingDelete(true)
              const { response } = await deleteTaxFee({
                applicationId: applicationId,
                applicationType: type,
                productId: record.id,
                type: "applicationFee",
              })
              if (response.status == 200) {
                setIsLoadingDelete(false)
                setNumberBefore(null)
                setNumberAfter(null)
                setDisable(true)
                handlegetListProduct()
                openNotificationChangeSuccess(
                  textAndRules.updateSuccess,
                  "",
                  "#389e0d"
                )
              } else {
                openNotificationFail(
                  "ただいま、サーバが混み合っています。",
                  "しばらくしてから再度操作してください。",
                  "#f81d22"
                )
              }
            }}
            okText="削除"
            cancelText="キャンセル"
            disabled={disable}
          >
            <Button
              style={{ height: 25, fontSize: 10 }}
              loading={isLoadingDelete}
              disabled={disable}
            >
              {"削除"}
            </Button>
          </Popconfirm>
        </Space>
      </div>
    </Spin>
  )
}

export default UpdateFee
