import { Breadcrumb, Typography } from "antd"
import { Link, navigate } from "gatsby"
import React, { useCallback, useEffect, useState } from "react"
import { getStoreById } from "../../../services/user"

import { Content } from "antd/lib/layout/layout"
import { getListProductForSPAdmin } from "../../../services/product"
import SearchTableListProduct from "./SearchTableListProduct"
import TableProduct from "./TableProduct"
import SearchTableListProductAll from "./SearchTableListProductAll"
import TableProductAll from "./TableProductAll"
import TabActivePage from "./TabActivePage"
export const ListProductsContext = React.createContext()

const SettingForProduct = ({ storeId }) => {
  const [store, setStore] = useState({
    name_company: "",
    dataInfo: [],
  })
  const [statusCount, setStatusCount] = useState({})
  const [pageActive, setPageActive] = useState("1")
  const getInfoStore = useCallback(async () => {
    console.log(storeId)
    if (storeId) {
      if (storeId && storeId !== "all" && !store?.name_company) {
        const { response } = await getStoreById(storeId)
        if (response?.data?.stores) {
          const { stores } = response.data
          const dataInfo = [
            {
              textTitle: "企業名",
              textContent: stores.name,
            },
            {
              textTitle: "担当者名",
              textContent:
                (stores?.rep_name ?? "") + " " + (stores?.rep_name_first ?? ""),
            },
            {
              textTitle: "住所",
              textContent:
                (stores?.address?.num1 ?? "") +
                (stores?.address?.num2 ?? "") +
                " " +
                (stores?.address?.region ?? "") +
                (stores?.address?.city ?? "") +
                " " +
                (stores?.address?.town ?? "") +
                " " +
                " " +
                (stores?.address?.building_name ?? ""),
            },
          ]
          setStore({
            name_company: response.data.stores.name,
            dataInfo: dataInfo,
          })
        } else {
          navigate(
            "/super-sales-commission-setting-company/?pageCurrent=1&pageSize=10"
          )
        }
      }
    }
  }, [storeId])

  useEffect(() => {
    getInfoStore()
  }, [getInfoStore])
  const [isLoading, setIsLoading] = useState(false)

  const [total, setTotal] = useState(0)
  const [dataProducts, setDataProducts] = useState(null)
  const [searchParams, setSearchParams] = useState({})

  const pageSizeDefault = 30

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: pageSizeDefault,
    },
  })
  const handlegetListProduct = async (value) => {
    console.log("searchParams", searchParams)
    setIsLoading(true)
    const dataSent = {
      offset:
        (tableParams.pagination.current - 1) * tableParams.pagination?.pageSize,
      limit: tableParams.pagination.pageSize,
      q: searchParams.keyword,
      tags: searchParams.origin?.length > 0 ? searchParams.origin : null,
      collection_id: searchParams.selectCollectionSmall
        ? [searchParams.selectCollectionSmall]
        : searchParams.selectCollectionMedium
        ? [searchParams.selectCollectionMedium]
        : searchParams.selectCollectionMax
        ? [searchParams.selectCollectionMax]
        : null,
      store_id:
        searchParams?.company?.length > 0
          ? searchParams?.company
          : storeId == "all"
          ? null
          : [storeId],
      allStatus: true,
    }
    if (pageActive == "2") {
      dataSent.status = ["published"]
    }
    if (pageActive == "4") {
      dataSent.status = ["draft"]
    }
    const { response } = await getListProductForSPAdmin(dataSent)
    if (response.status == 200) {
      setDataProducts(response.data.products)
      setStatusCount(response.data.statusCount)
      setTotal(response.data.count)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handlegetListProduct()
  }, [])

  useEffect(() => {
    setTableParams({
      pagination: {
        current: 1,
        pageSize: pageSizeDefault,
      },
    })
  }, [searchParams, pageActive])

  useEffect(() => {
    if (dataProducts) {
      handlegetListProduct()
    }
  }, [tableParams])

  const data = {
    total,
    isLoading,
    setTotal,
    tableParams,
    setTableParams,
    dataProducts,
    setSearchParams,
    setDataProducts,
    storeId,
    handlegetListProduct,
    statusCount,
    pageActive,
    setPageActive,
  }

  return (
    <div>
      <Content>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            padding: "10px",
          }}
        >
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item>
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>掲載管理</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/super-sales-commission-setting-company/?pageCurrent=1&pageSize=10">
                手数料設定
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span className="text-[#888888]">
                {storeId === "all"
                  ? "全企業販売手数料設定"
                  : `企業別販売手数料設定 －（${store?.name_company}）`}
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Typography.Title
            level={2}
            className="!text-2xl !pt-6 !font-normal !leading-[33px]] !tracking-[0.3px]"
          >
            {storeId === "all"
              ? "全企業販売手数料設定"
              : `企業別販売手数料設定 －（${store?.name_company}）`}
          </Typography.Title>
        </div>
        <ListProductsContext.Provider value={data}>
          {storeId == "all" ? (
            <div>
              <SearchTableListProductAll />
              <TabActivePage />
              <TableProductAll />
            </div>
          ) : (
            <div>
              <SearchTableListProduct />
              <TabActivePage />

              <TableProduct />
            </div>
          )}
        </ListProductsContext.Provider>
      </Content>
    </div>
  )
}

export default SettingForProduct
